import {Channel as TypeChannel, ChannelFilters, ChannelSort, DefaultGenerics, StreamChat} from "stream-chat";
import {
  Avatar,
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelPreviewUIComponentProps,
  ChannelSearchFunctionParams,
  ChannelSearchProps,
  Chat,
  InfiniteScroll,
  LoadingIndicator,
  MessageList,
  Window
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import "./DispatchMessaging.css";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AuthContext} from "../AuthProvider";
import {useAppContext} from "../../ApplicationContext";
import {CustomChannelPreview, ChatTitle, CustomSearchResultItem} from "./DispatchMessagingCommon";
import styled from "@emotion/styled";
import {Button, Drawer} from "@blueprintjs/core";
import {InforIcon} from "../job/AssignmentBarV2";
import {DefaultStreamChatGenerics} from "stream-chat-react/dist/types/types";
import useChatUserDetails from "./useChatUserDetails";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import {DispatchGroupDataContext, DispatchGroupDataState} from "../common/DispatchGroupDataProvider";
import {CustomMessage} from "./CustomMessage";
import {PreferenceContext, extractJsonPref} from "../../providers/PreferenceProvider";
import {UserPreferences} from "../common/Constants";
import {DEFAULT_CARD_CONFIGURATIONS_VALUE} from "../manifest/card-configuration/ManifestCardConfigurationDialog";
import {IConfigurationFormValues} from "../manifest/card-configuration/ManifestCardConfiguration";
import DispatchMessageInput from "./DispatchMessageInput";

type DispatchMessagingProps = {
  driverId?: number;
};

export type TSetSeletedChannel = (channel: TypeChannel<DefaultStreamChatGenerics> | undefined) => Promise<void>;

const sort: ChannelSort<DefaultGenerics> = {last_message_at: -1, has_unread: -1};

export const getDisplayTitle = (
  channel: TypeChannel<DefaultStreamChatGenerics> | undefined,
  cardConfigurationState: IConfigurationFormValues
): string => {
  const retVal = cardConfigurationState.driverName ? channel?.data?.name : (channel?.data?.driver_code as string);
  return retVal ?? "";
};

const DispatchMessaging = ({driverId}: DispatchMessagingProps) => {
  const {isSuperUser, tenantId} = useContext(AuthContext);
  const {appState, dispatch} = useAppContext();
  const {selectedDriverChannelId, isChatOpen} = appState;
  const {driverMessaging} = useContext(FeatureFlagContext);
  const chatUserDetails = useChatUserDetails(driverMessaging);
  const dispatchGroupData = useContext<DispatchGroupDataState>(DispatchGroupDataContext);
  const [chatClient, setChatClient] = useState<StreamChat | null>();
  const [selectedChannel, setSelectedChannel] = useState<TypeChannel<DefaultStreamChatGenerics> | undefined>();
  const {userPreferences} = useContext(PreferenceContext);
  const cardConfigurationState = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value as IConfigurationFormValues;

  const {userId, apiKey, userToken} = chatUserDetails ?? {};

  const channelTitle = useMemo(
    () => getDisplayTitle(selectedChannel, cardConfigurationState),
    [cardConfigurationState, selectedChannel]
  );

  const filters = useMemo(() => {
    const dispatchGroupIds = dispatchGroupData.driverGroups.map((driverGroup) => driverGroup.dispatchGroupId);
    const retValue: ChannelFilters = {
      team: {$eq: tenantId},
      type: {$eq: "driver"}
    } as any;
    if (!isSuperUser) {
      retValue.members = {$in: [userId ?? ""]};
    }
    if (dispatchGroupIds.length > 0) {
      retValue.dispatch_group_id = {$in: dispatchGroupIds};
    }
    return retValue;
  }, [dispatchGroupData.driverGroups, isSuperUser, tenantId, userId]);

  const customSearchFunction = useCallback(
    async (props: ChannelSearchFunctionParams, event: {target: {value: string}}, client: StreamChat) => {
      // eslint-disable-next-line react/prop-types
      const {setResults, setSearching, setQuery} = props;
      const value = event.target.value;
      setQuery(value);
      if (!value.trim()) return;
      const customFilters: ChannelFilters = {
        ...filters,
        $and: [
          {
            $or: [{name: {$autocomplete: value}}, {driver_code: {$autocomplete: value}}]
          }
        ]
      };
      setSearching(true);
      const channels = await client.queryChannels(customFilters, sort);
      setResults(channels);
      setSearching(false);
    },
    [filters]
  );

  const onSelecteChannel: TSetSeletedChannel = useCallback(
    async (channel: TypeChannel<DefaultStreamChatGenerics> | undefined) => {
      if (channel) {
        setSelectedChannel(channel);
      }
    },
    []
  );

  const additionalChannelSearchProps: Omit<ChannelSearchProps<DefaultGenerics>, "setChannels"> = useMemo(() => {
    return {
      searchFunction(params, event) {
        if (chatClient) {
          return customSearchFunction(params, event, chatClient);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      SearchResultItem: (props) => CustomSearchResultItem({...props, setSelectedChannel: onSelecteChannel})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, chatClient]);

  const renderAvatar = useCallback(
    () => <Avatar name={selectedChannel?.data?.name} size={39} />,
    [selectedChannel?.data?.name]
  );

  const renderMessage = useCallback(() => {
    return <CustomMessage driverTitle={channelTitle} />;
  }, [channelTitle]);

  const renderPreviewChannel = useCallback(
    (props: ChannelPreviewUIComponentProps<DefaultGenerics>) => (
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      <CustomChannelPreview
        {...props}
        setSelectedChannel={onSelecteChannel}
        active={selectedChannel?.cid === props?.channel?.cid}
      />
    ),
    [onSelecteChannel, selectedChannel?.cid]
  );

  const connect = useCallback(async (userId, apiKey, userToken) => {
    try {
      console.debug("Messaging: Connecting to streamchat");
      const client = StreamChat.getInstance(apiKey);
      const res = await client.connectUser({id: userId}, userToken);
      console.debug("Messaging: Connected to streamchat!");
      return {res, client};
    } catch (e) {
      console.error("Error connecting to streamchat", e);
      return null;
    }
  }, []);

  useEffect(() => {
    if (userId && apiKey && userToken) {
      connect(userId, apiKey, userToken).then((response) => {
        if (!response) return;
        const {res, client} = response;
        dispatch({
          type: "SetTotalUnreadChannel",
          payload: res?.me?.unread_channels ?? 0
        });
        setChatClient(client);
      });
    }
  }, [apiKey, connect, dispatch, userId, userToken]);

  useEffect(() => {
    if (chatClient) {
      chatClient.on((event) => {
        console.debug("Messaging: Event", event);
        const dispatch_group_ids = (filters?.dispatch_group_id as any)?.$in ?? [];
        const event_dispatch_group_id = (event?.channel_custom as any)?.dispatch_group_id;

        if (
          dispatch_group_ids &&
          dispatch_group_ids.length > 0 &&
          event_dispatch_group_id &&
          !dispatch_group_ids.includes(event_dispatch_group_id)
        ) {
          console.debug("Messaging: Event is not for the current dispatch group");
          return;
        }

        if (event.unread_channels !== undefined) {
          dispatch({
            type: "SetTotalUnreadChannel",
            payload: event.unread_channels ?? 0
          });
        }

        if (event.type === "connection.changed" && event.online === true) {
          console.debug("Messaging: Re-watching channels");
          chatClient.queryChannels(filters, sort, {
            watch: true,
            state: true
          });
        }

        if (selectedDriverChannelId && event.cid === selectedDriverChannelId) {
          let totalUnreadCount;

          if (event.type === "notification.mark_read") {
            totalUnreadCount = 0;
          }

          //check is message from the driver
          if (event.type === "message.new" && event?.user?.role === "driver") {
            totalUnreadCount = 1;
          }

          if (totalUnreadCount !== undefined) {
            dispatch({
              type: "SetSelectedDriverUnreadCount",
              payload: totalUnreadCount
            });
          }
        }
      });
    }
  }, [chatClient, dispatch, filters, selectedDriverChannelId]);

  useEffect(() => {
    setSelectedChannel(undefined);
  }, [filters]);

  useEffect(() => {
    if (!chatClient) {
      return;
    }
    if (driverId === undefined) {
      dispatch({type: "SetSelectedDriverChannelId", payload: undefined});
      dispatch({type: "SetSelectedDriverUnreadCount", payload: 0});
      setSelectedChannel(undefined);
    } else {
      chatClient
        .queryChannels(
          {
            ...filters,
            driver_id: {
              $eq: driverId
            }
          },
          sort
        )
        .then((queryDriverChannelRes) => {
          if (queryDriverChannelRes.length > 0) {
            const driverChannel = queryDriverChannelRes[0];
            dispatch({type: "SetSelectedDriverChannelId", payload: driverChannel.cid});
            dispatch({type: "SetSelectedDriverUnreadCount", payload: driverChannel.state.unreadCount > 0 ? 1 : 0});
            setSelectedChannel(driverChannel);
          }
        });
    }
  }, [chatClient, dispatch, driverId, filters]);

  return (
    <StyledChat
      isShow={isChatOpen}
      isOpen={true}
      enforceFocus={false}
      isCloseButtonShown={true}
      canOutsideClickClose={false}
      hasBackdrop={false}
      position="bottom-right"
      portalClassName="chat-portal"
    >
      {chatClient ? (
        <Chat client={chatClient}>
          <Container>
            <LeftContainer data-testid="chat-modal">
              <ChatTitle />
              <ChannelList
                filters={filters}
                sort={sort}
                showChannelSearch
                setActiveChannelOnMount={false}
                Paginator={InfiniteScroll}
                Preview={renderPreviewChannel}
                additionalChannelSearchProps={additionalChannelSearchProps}
                channelRenderFilterFn={(channels) => {
                  return channels.filter((channel) => {
                    const dispatch_group_ids = (filters?.dispatch_group_id as any)?.$in;
                    if (dispatch_group_ids && dispatch_group_ids.length > 0) {
                      return dispatch_group_ids.includes(channel.data?.dispatch_group_id);
                    }
                    return true;
                  });
                }}
              />
            </LeftContainer>
            <RightContainer>
              <CloseButton
                minimal
                icon="cross"
                data-testid="close-chat-button"
                onClick={() => {
                  dispatch({
                    type: "SetIsChatOpen",
                    payload: false
                  });
                }}
              />
              {selectedChannel && appState.isChatOpen ? (
                <Channel channel={selectedChannel}>
                  <Window>
                    <ChannelHeader title={channelTitle} Avatar={renderAvatar} />
                    <MessageList
                      Message={renderMessage}
                      messageActions={[]}
                      returnAllReadData
                      scrolledUpThreshold={50}
                    />
                    <DispatchMessageInput />
                  </Window>
                </Channel>
              ) : (
                <NoChannelContainer>
                  <InforText>
                    <InforIcon />
                    <span>Select the Driver to See the Chat or Send a Message</span>
                  </InforText>
                </NoChannelContainer>
              )}
            </RightContainer>
          </Container>
        </Chat>
      ) : (
        <LoadingIndicator />
      )}
    </StyledChat>
  );
};

export default DispatchMessaging;

const StyledChat = styled(Drawer)<{isShow: boolean}>`
  font-family: "Roboto", sans-serif;
  height: ${(props) => (props.isShow ? "50vh" : "0 !important")};
  min-height: ${(props) => (props.isShow ? "530px" : "0 !important")};
  bottom: 12px !important;
  right: 12px !important;
  left: calc(100vw - 600px) !important;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const LeftContainer = styled.div`
  width: 224px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

const RightContainer = styled.div`
  position: relative;
  flex-grow: 1;
  visibility: visible;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1000;
`;

const NoChannelContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InforText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  justify-content: center;
  max-width: 70%;
`;
