/* eslint-disable react/no-string-refs */
import {Text} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import {CircleWarningIcon} from "./JobColHeaderRenderer";

export interface JobColHeaderRendererParams {
  etaMessage: string | undefined;
}

const getWarningMessage = (message: string | undefined) => {
  if (!message || message === "Success") return "";
  return message;
};

const ETAColHeaderRenderer = ({etaMessage}: JobColHeaderRendererParams) => {
  const warningMsg = getWarningMessage(etaMessage);
  return (
    <Container data-testid="eta-col-header">
      <Left>
        <Text ellipsize>ETA</Text>
        {warningMsg && (
          <Tooltip2 content={warningMsg} popoverClassName="manifest-header-warning-tooltip" placement="top">
            <CircleWarningIcon />
          </Tooltip2>
        )}
      </Left>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export default ETAColHeaderRenderer;
