import React from "react";
import {DoubleFieldCellValue, StackedFieldCellRenderer} from "./DoubleFieldCellRenderer";
import {ManifestStop, NestedJobStop, Stop} from "../../../generated/graphql";
import {getStopCompletedTime, getStopPreposition} from "../../../utils/StopTime";
import {getJobStopDate, getTimeAtSpecificTimeZone, getLocalDate, getLocalTime} from "../../../utils/DateUtils";
import {ICellRendererParams} from "@ag-grid-community/core";
import {ETAStop} from "../../manifest/details/ManifestDetailsReducer";
import {toDate} from "date-fns-tz";
import {isCompleted} from "../../../services/ManifestStopService";

export enum StopDateTimeType {
  SCHEDULED_TIME,
  EARLY_TIME,
  LATE_TIME,
  POD_TIME,
  DEPARTURE_TIME,
  ARRIVAL_TIME,
  ETA,
  ETA_GENERATION_TIME,
  COMPLETED_TIME
}

export interface GetTimeCellValueProps {
  data: NestedJobStop | ManifestStop | ETAStop;
  is24HourFormat: boolean;
  isTimezoneOfJobStop: boolean;
  typeOfDateTime: StopDateTimeType;
  showPreposition?: boolean;
}

export const getStopDateTimeByType = (data: NestedJobStop | ETAStop | Stop | ManifestStop, type: StopDateTimeType) => {
  let stopTime;
  const {timeZone} = data;
  switch (type) {
    case StopDateTimeType.SCHEDULED_TIME:
      stopTime = data?.scheduledDateTime;
      break;
    case StopDateTimeType.EARLY_TIME:
      stopTime = data?.earlyDateTime;
      break;
    case StopDateTimeType.LATE_TIME:
      stopTime = data?.lateDateTime;
      break;
    case StopDateTimeType.POD_TIME:
      stopTime = data?.podDateTime;
      break;
    case StopDateTimeType.DEPARTURE_TIME:
      stopTime = data?.departDateTime;
      break;
    case StopDateTimeType.ARRIVAL_TIME:
      stopTime = data?.arriveDateTime;
      break;
    case StopDateTimeType.ETA:
      if (!isCompleted(data as ETAStop)) stopTime = (data as ETAStop)?.eta;
      break;
    case StopDateTimeType.ETA_GENERATION_TIME:
      if (!isCompleted(data as ETAStop)) stopTime = (data as ETAStop)?.etaGeneratedDateTime;
      break;
    case StopDateTimeType.COMPLETED_TIME:
      stopTime = getStopCompletedTime(data);
      break;
    default:
  }

  if (stopTime && timeZone) {
    return toDate(stopTime, {timeZone});
  }
  return undefined;
};

const getDoubleFieldCellValue = (localTime: string, localDate: string): DoubleFieldCellValue => {
  return {
    upperField: {
      value: localTime,
      style: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#111827"
      }
    },
    lowerField: {
      value: localDate,
      style: {
        fontSize: "14px",
        fontWeight: "lighter",
        color: "#6B7280"
      }
    }
  };
};

const getDate = (stopTime: string, timeZone: string, isTimezoneOfJobStop: boolean) => {
  return isTimezoneOfJobStop
    ? getJobStopDate(stopTime, timeZone, "MMM dd")
    : getLocalDate(stopTime, timeZone, "MMM dd");
};

const getTime = (stopTime: string, timeZone: string, isTimezoneOfJobStop: boolean, is24HourFormat: boolean) => {
  return isTimezoneOfJobStop
    ? getTimeAtSpecificTimeZone({
        dateStr: stopTime,
        timeZone: timeZone,
        isTimeFormat24hr: is24HourFormat,
        options: {includeZone: true}
      })
    : getLocalTime({
        dateStr: stopTime,
        timeZone: timeZone,
        isTimeFormat24hr: is24HourFormat,
        options: {includeZone: true}
      });
};

export const getStopTimeCellValue = ({
  isTimezoneOfJobStop,
  is24HourFormat,
  data,
  typeOfDateTime,
  showPreposition = true
}: GetTimeCellValueProps) => {
  const stopTime = getStopDateTimeByType(data, typeOfDateTime!);
  if (!stopTime || !data.timeZone) {
    return undefined;
  }

  let localTime = getTime(stopTime.toISOString(), data.timeZone, isTimezoneOfJobStop, is24HourFormat);

  const stopPrep = getStopPreposition(data);
  if (showPreposition && stopPrep) {
    localTime = `${stopPrep} ${localTime}`;
  }

  const localDate = getDate(stopTime.toISOString(), data.timeZone, isTimezoneOfJobStop);

  return getDoubleFieldCellValue(localTime, localDate!);
};

export const TimeCellRenderer = (params: ICellRendererParams<DoubleFieldCellValue | undefined>) => {
  if (!params.value) {
    return <></>;
  }
  return StackedFieldCellRenderer(params.value);
};
